.gpt3__cta {
    display: flex;
    justify-content: space-between;
    align-items: center;

    padding: 0 30px;
    min-height: 172.65px;
    background: var(--gradient-bar);
    border: 1px solid #000;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10.7236px;
}

.gpt3__cta-content>p {
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 12px;
    line-height: 30px;
    color: #0E0E0E;
}

.gpt3__cta-content>h3 {
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 24px;
    line-height: 45px;
    color: #000000;
}

.gpt3__cta-btn>button {
    width: 189.14px;
    height: 59.4px;
    background: #000000;
    border-radius: 40px;
    border: none;
    outline: none;
    cursor: pointer;

    font-family: var(--font-family);
    font-weight: 700;
    font-size: 18px;
    line-height: 30px;
    color: #FFFFFF;
}

.gpt3__cta-btn>button:hover {
    transform: scale(1.05);
    transition: 0.5s ease all;
    color: tomato;
}

@media screen and (max-width:650px) {
    .gpt3__cta {
        flex-direction: column;
        padding: 20px 30px;
    }

    .gpt3__cta-btn {
        margin-top: 2rem;
    }

}

@media screen and (max-width:550px) {
    .gpt3__cta-content>h3 {
        font-size: 18px;
        line-height: 32px;
    }

    .gpt3__cta-btn>button {
        width: 169.14px;
        height: 49.4px;

        font-size: 14px;
        line-height: 28px;
    }
}