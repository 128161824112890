.gpt3__blog{
    display: flex;
    flex-direction: column;
}

.gpt3__blog-heading>h1{
   font-family: var(--font-family);
    font-weight: 800;
    font-size: 62px;
    line-height: 75px;
    letter-spacing: -0.04em;
    margin-bottom: 3rem;
}

.gpt3__blog-container{
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-gap: 50px;
}

.gpt3__blog-container_groupB{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 50px;
}


@media screen and (max-width:990px) {
    .gpt3__blog-container{
        display: flex;
        flex-direction: column-reverse;
    }
}


@media screen and (max-width:650px) {
    .gpt3__blog-container_groupA
    .gpt3__blog-container_article 
    .gpt3__blog-container_article-content 
    .gpt3__blog-container_article-content_heading>h1{
        font-size: 19px;
    }

    .gpt3__blog-container_groupB{
        grid-template-columns: repeat(1, 1fr);

    }

    .gpt3__blog-heading>h1{
        font-size: 46px;
        line-height: 52px;
    }
}

@media screen and (max-width:450px) {
    .gpt3__blog-heading>h1{
        font-size: 32px;
        line-height: 40px;
    }
}