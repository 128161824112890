.gpt3__header {
    display: flex;
}

.gpt3__header-content {
    flex: 1;
}

.gpt3__header-content>h1 {
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 62px;
    line-height: 75px;
    letter-spacing: -0.04em;
    margin-top: 3rem;
}

.gpt3__header-content>p {
    max-width: 554px;
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 20px;
    line-height: 27px;
    color: var(--color-text);
    margin-top: 1.5rem;
}

.gpt3__header-content_email {
    display: flex;
    margin: 2rem 0 1rem;
    width: 100%;
}

.gpt3__header-content_email>input {
    width: 100%;
    flex: 2;
    background: var(--color-footer);
    border-radius: 5px 0px 0px 5px;
    padding: 1.3rem 1rem;
    border: none;
    outline: none;

    font-family: var(--font-family);
    font-weight: 400;
    font-size: 20px;
    line-height: 27px;
    color: #fff;
}

.gpt3__header-content_email>button {
    flex: 0.6;
    width: 100%;
    background: #FF4820;
    border-radius: 0px 5px 5px 0px;
    border: none;
    outline: none;
    padding: 1.3rem 1rem;
    cursor: pointer;

    font-family: var(--font-family);
    font-weight: bold;
    font-size: 20px;
    line-height: 27px;
    color: #FFFFFF;
}

.gpt3__header-content_people {
    display: flex;
    width: 100%;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
}

.gpt3__header-content_people>img {
    width: 181.79px;
    height: 38px;
}

.gpt3__header-content_people>p {
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 12px;
    color: #FFFFFF;
    line-height: 38px;
}

.gpt3__header-img_container {
    flex: 1;
    display: flex;
    align-items: flex-start;
    justify-content: center;
}

.gpt3__header-img_container img {
    width: 95%;
    height: 95%;
}

@media screen and (max-width:1050px){
    .gpt3__header{
        flex-direction: column;
    }

    .gpt3__header-content{
        margin: 0 0 3rem;
    }

    .gpt3__header-content>h1{
        margin-top: 0rem;
    }
}

@media screen and (max-width: 768px) {
    .gpt3__header-content_email input,
    .gpt3__header-content_email>button{
        font-size: 16px;
        line-height: 22px;
        width: auto;
        padding: 0.7rem;
    }
}

@media screen and (max-width: 650px){
    .gpt3__header-content>h1{
        font-size: 47px;
        line-height: 52px; 
    }

    .gpt3__header-content>p{
        font-size: 16px;
        line-height: 24px;
    }

    .gpt3__header-content_email{
        flex-direction: column;
    }

    .gpt3__header-content_people{
        flex-direction: column;
        gap: 5px;
        margin-top: 2rem;
    }

    .gpt3__header-content_email input,
    .gpt3__header-content_email>button{
        font-size: 16px;
        line-height: 22px;
        width: auto;
        padding: 0.7rem;
    }

    .gpt3__header-content_email>button{
        width: 200px;
        margin-top: 2rem;
        align-self: center;
    }
}

@media screen and (max-width: 490px){
    .gpt3__header-content>h1{
        font-size: 36px;
        line-height:48px;
    }

    .gpt3__header-content>p{
        font-size: 14px;
        line-height: 24px;
    }

    .gpt3__header-content_email input,
    .gpt3__header-content_email>button{
        font-size: 12px;
        line-height: 16px;
    }
}