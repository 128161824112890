.gpt3__blog-container_article{
    height: 100%;
    display: grid;
    grid-template-rows: 1fr 1.5fr;
}
.gpt3__blog-container_article-image{
    grid-row: 1/2;
}

.gpt3__blog-container_article-image>img{
    width: 100%;
    height: 100%;
  
}

.gpt3__blog-container_article-content{
    grid-row: 2/2;
    background: var(--color-blog);
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.gpt3__blog-container_article-content>p{
    cursor: pointer;
}