.gpt3__footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: var(--color-footer);
}

.gpt3__footer-heading {
    width: 100%;
    text-align: center;
}

.gpt3__footer-heading>h1 {
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 62px;
    line-height: 75px;
    text-align: center;
    letter-spacing: -0.04em;
}

.gpt3__footer-btn {
    width: 218px;
    height: 65px;
    border: 1px solid #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 3rem;
    margin-bottom: 8rem;
    cursor: pointer;

    font-family: 'Gilroy-Medium';
    font-size: 18px;
    line-height: 21px;
    color: #FFFFFF;
}

.gpt3__footer-btn>p {
    word-spacing: 2px;
}

.gpt3__footer-links {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-flow: row wrap;
}

.gpt3__footer-links>div {
    width: 250px;
    margin: 1rem;
}

.gpt3__footer-links_logo {
    display: flex;
    flex-direction: column;
}

.gpt3__footer-links_logo>img {
    width: 118px;
    height: 30px;
}

.gpt3__footer-links_logo>p {
    font-family: 'Gilroy-Medium';
    font-size: 12px;
    line-height: 14px;
    color: #FFFFFF;
    margin-top: 2rem;
}



.gpt3__footer-links_div {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.gpt3__footer-links_div>h4,
.gpt3__footer-links_div>p {
    font-family: 'Gilroy-Bold';
    color: #FFFFFF;
}

.gpt3__footer-links_div>h4 {
    font-size: 14px;
    line-height: 16px;
    margin-bottom:1rem;
}

.gpt3__footer-links_div>p {
    font-size: 12px;
    line-height: 14px;
    margin: 0.5rem 0;
    cursor:pointer;
}


.gpt3__footer-copyright {
    font-family: 'Gilroy-Medium';
    font-size: 12px;
    line-height: 14px;
    color: #FFFFFF;
    margin-top: 3rem;
}

@media screen and (max-width:990px) {

    .gpt3__footer-heading br {
        display: none;
    }
}

@media screen and (max-width:850px) {
    .gpt3__footer-heading>h1 {
        font-size: 44px;
        line-height: 50px;
    }
}

@media screen and (max-width:550px) {
    .gpt3__footer-heading>h1 {
        font-size: 34px;
        line-height: 42px;
    }
    
    .gpt3__footer-links>div{
        margin: 1rem 0;
    }
    
    .gpt3__footer-btn{
        height: max-content;
        width: max-content;
        padding: 1rem;
    }
    
    .gpt3__footer-btn>p{
        font-size: 14px;
        line-height:20px;
    }
}

@media screen and (max-width:400px) {
    .gpt3__footer-heading>h1 {
        font-size: 27px;
        line-height: 38px;
    }
}